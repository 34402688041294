import React from "react";
import "./HomeDisplay.css";

class HomeDisplay extends React.Component {
  render() {
    return (
      <div id="HomeDisplay">
      <div className="big-text-container">
        <span className="big-text">Web Developement</span>
        <span className="big-text">Business Automation</span>
        <span className="big-text">Software Developement</span>
      </div>
        
        
        
      </div>
    );
  }
}

export default HomeDisplay;
